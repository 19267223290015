.logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.title {
  color: var(--secondary);
  font-size: 20px;
}

@media (max-width: 540px) {
  .title {
    font-size: 16px;
  }
}