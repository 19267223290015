.avatar {
  width: 48px;
  height: 48px;
  border-radius: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 0;
}

.avatar--small {
  width: 40px;
  height: 40px;
}

.avatar--large {
  width: 80px;
  height: 80px;
}

.avatar--border {
  padding: 1px;
  background: linear-gradient(
    135deg,
    rgba(9, 16, 197, 1) 0%,
    rgba(32, 188, 95, 1) 100%
  );
}

.avatar--interactive {
  cursor: pointer;
}

.avatar--large.avatar--border {
  padding: 2px;
}

.avatar__content {
  width: 100%;
  height: 100%;
  background-color: var(--bg4);
  border-radius: 25%;
  overflow: hidden;
}

.avatar--border .avatar__content {
  border: 1px solid var(--bg3);
}

.avatar--large.avatar--border .avatar__content {
  border: 2px solid var(--bg3);
}

.avatar__img {
}
