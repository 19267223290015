.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--bg3);
  opacity: 0.4;
  width: 100%;
  height: 100%;
  z-index: 9;
}
