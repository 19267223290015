.layout {
  display: flex;
  min-height: var(--full-height);
}

.layout__container {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  min-width: 0;
  padding: 24px 16px;
}

.layout__content {
  display: flex;
  flex: 1 1;
  min-width: 0;
  padding: 32px 0;
}

.layout__main-content {
  flex: 1 1;
  min-width: 0;
}

.layout__menu {
  flex-shrink: 0;
}

@media screen and (min-width: 768px) {
  .layout__container {
    padding: 24px;
  }
}

@media screen and (min-width: 1600px) {
  .layout__container {
    padding: 40px;
    margin: 12px 0;
    background-color: var(--bg2);
    border-radius: 24px;
  }
  .layout__content {
    padding-bottom: 0;
  }
  .layout__main-content {
    /* we need this to prevent the content from overflowing the container */
    width: 0;
    margin-right: 40px;
  }
  .layout--settings .layout__main-content {
    margin-right: 0;
  }
}
