.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__left,
.header__right {
  display: flex;
  align-items: center;
}

.header__button:not(:last-child) {
  margin-right: 12px;
}

.header__user-name {
  display: block;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 4px;
}

.header__user-id {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  color: var(--secondary);
}

.header__user-id-text {
  margin-right: 4px;
}

.header__user-info {
}
