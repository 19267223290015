.drawer {
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--bg1);
  width: 100%;
  height: 100%;
  overflow-y: auto;
  z-index: 10;
}

.drawer__container {
}

.drawer--right {
  right: 0;
  left: auto;
}

.drawer__close-button {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 32px;
}
