/* fonts */
@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Gilroy Medium'), url(../fonts/Gilroy-Medium.woff2) format('woff2'),
    url(../fonts/Gilroy-Medium.ttf) format('opentype');
}
@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Gilroy Semibold'),
    url(../fonts/Gilroy-Semibold.woff2) format('woff2'),
    url(../fonts/Gilroy-Semibold.ttf) format('opentype');
}
@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Gilroy Bold'), url(../fonts/Gilroy-Bold.woff2) format('woff2'),
    url(../fonts/Gilroy-Bold.ttf) format('opentype');
}
