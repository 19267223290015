.simple-header {
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 1600px) {
  .simple-header {
    justify-content: flex-start;
  }
}
