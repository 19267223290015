.auth-layout {
  display: flex;
  padding: 24px 16px;
  min-height: var(--full-height);
}

.auth-layout__container {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.auth-layout__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1;
}

.auth-layout__illustration {
  background-image: url(../../images/png/reg-log.png);
  background-image: image-set(
    url(../../images/png/reg-log.png) 1x,
    url(../../images/png/reg-log@2x.png) 2x
  );
  background-repeat: no-repeat;
  background-size: cover;
  width: 45%;
  border-radius: 24px;
}

@media screen and (min-width: 768px) {
  .auth-layout {
    padding: 32px 24px;
  }
}

@media screen and (min-width: 1600px) {
  .auth-layout {
    display: flex;
    padding: 12px;
  }
  .auth-layout__container {
    flex-grow: 1;
    padding: 28px 80px;
  }
  .auth-layout__content {
    width: 100%;
    max-width: 500px;
    align-self: center;
  }
}
