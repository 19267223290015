.wallet {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
  padding-left: 24px;
  border: 1px solid var(--lines);
  border-radius: 16px;
}

.wallet__content {
  display: flex;
}

.wallet__currency:not(:last-child) {
  position: relative;
  padding-right: 16px;
  margin-right: 16px;
}

.wallet__currency:not(:last-child)::after {
  content: '';
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 24px;
  background-color: var(--lines);
}

.wallet__currency-title {
  font-size: 12px;
  color: var(--secondary);
}

.wallet__currency-value {
  display: flex;
  align-items: center;
}

.wallet__currency-text {
  font-size: 16px;
  font-weight: 600;
}

.wallet__currency-icon {
  margin-right: 8px;
}
