.card {
  background-color: var(--bg3);
  border-radius: 16px;
  padding: 16px;
}

@media screen and (min-width: 768px) {
  .card {
    border-radius: 20px;
    padding: 24px;
  }
}
@media screen and (min-width: 1600px) {
  .card {
    border-radius: 24px;
  }
}
