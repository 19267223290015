.error {
  display: flex;
  flex-direction: column;
  padding: 32px 16px;
  min-height: var(--full-height);
  background-image: url(../../images/png/illustration-no-connect.png);
  background-image: image-set(
    url(../../images/png/illustration-no-connect.png) 1x,
    url(../../images/png/illustration-no-connect@2x.png) 2x
  );
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 70%;
}

.error--not-found {
  background-image: url(../../images/png/illustration-404.png);
  background-image: image-set(
    url(../../images/png/illustration-404.png) 1x,
    url(../../images/png/illustration-404@2x.png) 2x
  );
  background-size: contain;
  background-position: center;
}

.error__content {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.error__message {
  display: flex;
  flex-direction: column;
  padding: 48px 0;
  max-width: 350px;
}

.error__title {
  text-align: center;
  font-size: 20px;
  margin-bottom: 24px;
}

@media screen and (min-width: 768px) {
  .error {
    padding: 48px 24px;
    background-size: 70%;
    background-position: bottom right;
  }
  .error--not-found {
    background-position: center right;
  }
  .error__content {
    justify-content: flex-start;
  }
  .error__header {
    justify-content: flex-start;
  }
  .error__message {
    max-width: 500px;
    padding: 80px 0;
  }
  .error__title {
    text-align: left;
    font-size: 40px;
    margin-bottom: 32px;
  }
  .error__button {
    max-width: 200px;
  }
}

@media screen and (min-width: 1600px) {
  .error {
    padding: 48px 120px;
    background-size: 60%;
    background-position: bottom right;
  }
  .error__content {
    background-size: 70%;
  }
  .error__message {
    max-width: 800px;
    padding: 160px 0;
  }
  .error__title {
    font-size: 64px;
    margin-bottom: 40px;
  }
}
