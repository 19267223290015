.social-widget {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.social-widget__button:not(:last-child) {
  margin-bottom: 12px;
}

/* fix icon styles for full fill icons */
.icon path {
  stroke: none;
  fill: currentColor;
}
