@media screen and (min-width: 1600px) {
  .menu-sidebar {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 284px;
    padding: 24px;
    padding-top: 52px;
  }
  .menu-sidebar--collapsed {
    width: 112px;
  }
  .menu-sidebar__logo {
    margin-bottom: 80px;
  }
  .menu-sidebar__menu {
    margin-bottom: 100px;
  }
  .menu-sidebar__collapse-button {
    position: absolute;
    top: 42px;
    right: -24px;
    /* width: 22.5px; */
    height: 70px;
    width: 24px;
    background: url(../../images/svg/collapse-bg.svg);
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    color: var(--secondary);
  }

  .menu-sidebar__collapse-button svg {
    position: absolute;
    top: 50%;
    left: -12px;
    transform: translateY(-50%);
  }

  .menu-sidebar__collapse-button path {
    stroke: currentColor;
  }
  .menu-sidebar__social-widget {
  }
}

@media (hover: hover) {
  .menu-sidebar__collapse-button:hover path {
    color: var(--white);
  }
}
