.menu {
}

.menu__item {
}

.menu__links {
}

.menu__link {
  position: relative;
  display: flex;
  align-items: center;
  padding: 16px;
  color: var(--secondary);
}

.menu__link--active {
  color: var(--white);
}

.menu__link--active .menu__link-icon {
  color: var(--green);
}

.menu__link--active::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  width: 2px;
  height: 16px;
  transform: translateY(-50%);
  background-color: var(--green-lighter);
  border-radius: 1px;
}

.menu__link-icon {
  display: flex;
  margin-right: 10px;
  color: var(--secondary);
}

.menu__link-icon path,
.menu__link-icon rect,
.menu__link-icon ellipse,
.menu__link-icon circle {
  stroke: currentColor;
}

.menu__link-text {
}

@media (hover: hover) {
  .menu__link:hover .menu__link-icon {
    color: var(--white);
  }
}
