/* bring in normalize.css styles */
@import-normalize;

/* R E S E T */
* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  cursor: pointer;
}

button {
  display: block;
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  color: inherit;
}

ul,
ol {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
}

li {
  list-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  line-height: inherit;
  font-size: 100%;
  margin: 0;
}
