.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* flex-grow: 1; */
  background-color: var(--green);
  color: var(--white);
  border-radius: 16px;
  padding: 22px;
  width: 100%;
}

.button path,
.button rect,
.button ellipse,
.button circle {
  stroke: currentColor;
}

.button:not(.button--empty) svg {
  margin-right: 10px;
}

.button--small {
  padding: 14px;
}

.button--medium {
  padding: 20px;
}

.button--with-icon.button--small {
  padding: 12px;
}

.button--with-icon.button--medium {
  padding: 16px;
}

.button--with-icon {
  padding: 20px;
}

.button:not(:disabled):active {
  background-color: var(--green-light);
}

.button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@media (hover: hover) {
  .button:not(:disabled):hover {
    background-color: var(--green-light);
  }
}
