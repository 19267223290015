.profile {
}

.profile__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
}

.profile__content {
  overflow: hidden;
}

.profile__title {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 24px;
}

.profile__wallet {
  margin-bottom: 16px;
}

.profile__card:not(:last-child),
.profile__bonus-card:not(:last-child) {
  margin-bottom: 12px;
}

.profile__bonus-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.bonus-card__extra {
  margin-top: 10px;
  text-align: center;
}

.dynamicIncomeBtn {
  background-color: #26194a;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal__content {
  background: #26194a;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 100%;
  color: white;
  position: relative;
}

.modal__close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: red;
  font-size: 24px;
  cursor: pointer;
}
.modal__close:hover {
  color: darkred;
}

.graph {
  margin-top: 20px;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

.noDataMessage {
  text-align: center;
  font-size: 16px;
  color: #ffffff;
  margin-top: 20px;
}

@media screen and (min-width: 768px) {
  .profile__info {
    display: flex;
  }
  .profile__header {
    margin-bottom: 0;
    margin-right: 24px;
  }
  .profile__content {
    width: 100%;
  }
  .profile__title {
    text-align: left;
  }
  .profile__bonus-cards-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  .profile__bonus-card {
    width: calc(50% - 6px);
  }
  .profile__bonus-card:last-child {
    margin-bottom: 12px;
  }
  .profile__bonus-card:nth-child(odd) {
    margin-right: 12px;
  }
}

@media screen and (min-width: 1600px) {
  .profile__info {
    display: block;
  }
  .profile__header {
    justify-content: center;
    margin-right: 0;
    margin-bottom: 24px;
  }
  .profile__title {
    text-align: center;
  }
  .profile__card:not(:last-child),
  .profile__bonus-card:not(:last-child) {
    margin-bottom: 24px;
  }
  .profile__bonus-card {
    width: calc(50% - 12px);
  }
  .profile__bonus-card:nth-child(odd) {
    margin-right: 24px;
  }
  .profile__bonus-card:last-child {
    margin-bottom: 24px;
  }
}
