.input__wrapper {
}

.input {
  padding: 10px 24px;
  height: 64px;
  border: 1px solid var(--lines);
  border-radius: 16px;
  display: flex;
  align-items: center;
  color: var(--secondary);
  min-width: 180px;
}

.input path,
.input rect,
.input ellipse,
.input circle {
  stroke: currentColor;
}

.input--green {
  border-color: var(--green);
  color: var(--green);
}

.input--red {
  border-color: var(--red);
  color: var(--red);
}

.input--yellow {
  border-color: var(--yellow);
  color: var(--yellow);
}

.input--button {
  padding: 3px;
  padding-left: 24px;
}

.input--disabled {
  pointer-events: none;
  opacity: 0.5;
}

.input--clickable {
  cursor: pointer;
}

.input__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  height: 100%;
  margin-right: 12px;
}

.input__icon {
  display: flex;
}

.input__icon--left {
  margin-right: 12px;
}

.input__label {
  cursor: inherit;
  font-size: 12px;
  font-weight: 500;
}

.input__field {
  all: unset;
  font-size: 16px;
  font-weight: 600;
  color: var(--white);
}

.input__field::placeholder {
  color: var(--secondary);
  opacity: 0.6;
}

.input__error {
  padding-top: 4px;
  display: flex;
  align-items: center;
  color: var(--red);
}

.input__error-icon {
  flex-shrink: 0;
  margin-right: 4px;
}

.input__error-icon path {
  fill: currentColor;
}

.input__error-icon path:last-of-type,
.input__error-icon rect {
  stroke: currentColor;
}

.input__icon--right {
}

.input__error-text {
}
