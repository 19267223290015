.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__left,
.header__right {
  display: flex;
  align-items: center;
}

.header__button:not(:last-child) {
  margin-right: 12px;
}
