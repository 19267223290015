/* default button styles */
.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border-radius: 25%;
  background-color: var(--green);
  color: var(--white);
}
/* button icon styles */
.button path,
.button rect,
.button ellipse,
.button circle {
  stroke: currentColor;
}

.button:not(:disabled):active {
  background-color: var(--green-light);
}

.button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* button size styles */
.button--small {
  padding: 8px;
}

.button--medium {
  padding: 12px;
}
/* button appearance styles */
.button--secondary {
  background-color: var(--bg4);
  color: var(--secondary);
}

.button--secondary:not(:disabled):active {
  background-color: var(--bg4);
  color: var(--green);
}

/* change padding to -1px to remove the border */
.button--ghost {
  background-color: transparent;
  border: 1px solid var(--lines);
  padding: 15px;
  color: var(--secondary);
}

/* change padding to -1px to remove the border */
.button--ghost.button--small {
  padding: 7px;
}

/* change padding to -1px to remove the border */
.button--ghost.button--medium {
  padding: 11px;
}

.button--ghost:not(:disabled):active {
  background-color: transparent;
  border-color: var(--green);
  color: var(--green);
}

@media (hover: hover) {
  .button:not(:disabled):hover {
    background-color: var(--green-light);
  }

  .button--secondary:not(:disabled):hover {
    background-color: var(--bg4);
    color: var(--white);
  }

  .button--ghost:not(:disabled):hover {
    background-color: transparent;
    border-color: var(--secondary);
  }
}
