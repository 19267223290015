.button {
  display: flex;
  color: var(--secondary);
}

.button path {
  stroke: currentColor;
}

.button:active path {
  color: var(--green);
}

@media (hover: hover) {
  .button:hover path {
    color: var(--white);
  }
}
