:root {
  --bg1: #0d0721;
  --bg2: #110928;
  --bg3: #160c32;
  --bg4: #2c1c57;
  --lines: #26194a;
  --white: #ffffff;
  --green: #20bc5f;
  --green-light: #3dd178;
  --green-lighter: #99db59;
  --secondary: #6a559e;
  --yellow: #f6a428;
  --red: #ef6868;
  --purple: #6433f0;

  --hover: #8c90ff;
  --no-active: #60628f;

  --default-blue-color: #4d53f1;
  --hover-blue-color: #494fea;
  --active-blue-color: #444ae0;
  --white-color: #ffffff;
  --purple-color: #8b8fff;
  --lite-purple-color: #6163b3;
  --gray-color: #24253a;
  --dark-gray-color: #222334;
  --lite-gray-color: #40415c;
  --hover-gray-color: #2b2c49;
  --active-gray-color: #373861;
  --dark-blue-color: #292959;
  --lite-black-color: #141420;
  --green-color: #21bc60;
  --lite-green-color: #66fca3;
  --yellow-border-color: #e8ec00;

  --full-height: 100vh;

  /* notification styles  */
  --toastify-font-family: 'Gilroy', 'Open Sans', 'Arial', sans-serif;
  --toastify-color-dark: var(--bg4);
}

body {
  font-family: 'Gilroy', 'Open Sans', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  margin: 0;
  color: var(--white);
  background-color: var(--bg1);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.3;
}

p,
span {
  line-height: 1.4;
}

/* autofill styles */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: var(--white);
  transition: background-color 600000s 0s, color 600000s 0s;
}

/* scrollbar styles  */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-track {
  background: transparent; /* цвет фона у дорожки */
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2) inset; /* тень у дорожки */
}
::-webkit-scrollbar-thumb {
  background: var(--no-active); /* цвет фона у дорожки */
  border-radius: 2px; /* скругление углов */
  border: none; /* толщина, стиль и цвет рамки */
}
::-webkit-scrollbar-thumb:hover {
  background: var(--hover); /* ползунок меняет цвет при наведении */
}

/* fix height for mobile safari */
@supports (height: 100dvh) {
  :root {
    --full-height: 100dvh;
  }
}
