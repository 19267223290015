.title {
  font-size: 20px;
  font-weight: 600;
}

@media screen and (min-width: 768px) {
  .title {
    font-size: 32px;
  }
}
