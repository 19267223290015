.tooltip {
  display: flex;
  position: relative; /* making the .tooltip span a container for the tooltip text */
  cursor: help;
}

.tooltip:before {
  content: attr(data-text); /* here's the magic */
  position: absolute;

  top: 100%;
  margin-top: 16px;

  left: 0;

  /* basic styles */
  width: 200px;
  padding: 24px;
  border-radius: 16px;
  border-top-left-radius: 0;

  background: var(--bg4);
  color: var(--secondary);
  text-align: left;

  /* display: none; hide by default */
  opacity: 0;
  transition: 0.3s opacity;
  pointer-events: none;
}

.tooltip--left:before {
  /* reset defaults */
  left: initial;
  border-radius: 16px;
  border-top-right-radius: 0;

  /* set new values */
  right: 0;
}

@media (hover: hover) {
  .tooltip:hover:before {
    /* display: block; */
    opacity: 1;
  }
  .tooltip:hover rect,
  .tooltip:hover rect + path {
    stroke: var(--white);
  }
  .tooltip:hover path + path {
    fill: var(--white);
  }
}
