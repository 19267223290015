.bonus-card {
  display: flex;
  flex-direction: column;
}

.bonus-card__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bonus-card__icon {
  margin-right: 12px;
}

.bonus-card__extra {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bonus-card__extra button {
  padding: 8px 16px;
  background-color: #26194a;
  border: none;
  border-radius: 12px;
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
}

.bonus-card__extra button:hover {
  background-color: #2c1c57;
}

.bonus-card__content {
  display: flex;
  flex-direction: column;
}

.bonus-card__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  color: var(--secondary);
  margin-bottom: 12px;
  margin-top: 12px;
}

.bonus-card__value-wrapper {
  display: flex;
}

.bonus-card__value {
  font-size: 16px;
  font-weight: 600;
  margin-right: 4px;
}

.bonus-card__currency {
  font-size: 12px;
  color: var(--secondary);
}