.social-widget {
  padding-top: 20px;
  min-width: 340px;
  max-width: 380px;
}

.social-widget__container {
  display: flex;
  /* justify-content: center; */
  position: relative;
  padding: 16px 32px;
  padding-left: 140px;
  background: var(--bg3);
  background: linear-gradient(135deg, rgba(46, 24, 107, 1) 0%, var(--bg3) 50%);
  border-radius: 24px;
}

.social-widget__content {
  display: flex;
  flex-direction: column;
}

.social-widget__image {
  position: absolute;
  bottom: 0;
  left: 0;
}

.social-widget__text {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 12px;
  max-width: 220px;
}

.social-widget__button {
  margin-right: 12px;
}

.social-widget__button:last-child {
  margin-right: 0;
}

/* fix icon styles for full fill icons */
.icon path {
  stroke: none;
  fill: currentColor;
}

@media screen and (min-width: 768px) {
  .social-widget {
    min-width: initial;
    max-width: initial;
    width: 100%;
    padding-top: 24px;
  }
  .social-widget__container {
    padding: 32px 24px;
    padding-top: 140px;
  }
  .social-widget__image {
    top: -24px;
    left: 50%;
    transform: translateX(-50%);
  }
  .social-widget__text {
    text-align: center;
    margin-bottom: 20px;
  }
}
